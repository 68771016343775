.App {
  text-align: center;
  margin: 1em;
}

.user-list {
  background-color: rgb(243, 198, 130);
  border: 2px solid black;
  width: 200px;
}

.video-container {
  position: relative;
  width: 250px;
  height: 175px;
  margin: 1em;
  border: 2px solid black;
  background-color: gray;
  border-radius: 15px;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.my-video {
  border: 3px solid firebrick;
}

.row-wrap-container {
  display: flex;
  flex-wrap: wrap;
}

.audio-graph-controls {
  margin: 1em;
}